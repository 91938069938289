import styled from "styled-components";
import media from "styled-media-query";

export const LayoutWrapper = styled.section`
  display: flex;
`;


export const LayoutMain = styled.main`

  background: #fafafa;
  padding-top: 10px;
  width: 100%;  
  @media screen and (min-width: 100px) and (max-width: 1300px){
   
    width: 1300px;  
  }
  
   ${media.greaterThan("2000px")`
    padding-left: 10%;
    padding-right: 10%;
  `}
  ${media.greaterThan("2300px")`
  padding-left: 15%;
  padding-right: 15%;
  
`}
${media.greaterThan("2600px")`
padding-left: 20%;
padding-right: 20%;

`}
${media.greaterThan("3000px")`
padding-left: 25%;
padding-right: 25%;

`}
${media.greaterThan("3300px")`
padding-left: 30%;
padding-right: 30%;

`}

${media.greaterThan("4000px")`
padding-left: 40%;
padding-right: 40%;

`}
`;

