import React, { useEffect } from "react";
import "../bulma.scss";

import CatLayout from "../components/CatLayout";
import CatJobsBanner from "../components/CatJobsBanner";
import CatContact from "../components/CatContact";

const Home = () => {
  return (
    <CatLayout>
      <br></br><br></br><br></br>
      <CatContact />
    </CatLayout>
  );
};

export default Home;
