import { Link } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

export const CatContact = styled.div`
  background-color: #E0E0E0;    
  padding: 3rem var(--default-padding-desktop);
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;  
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;

export const Wrapper = styled.div`
  width: 80%;
  padding: 30px;
  background-color: #ffffff;
`;

export const CatContactForm = styled.form`  
  align-items: center;
`;

export const CatContactFormField = styled.div`
  width: 80%;
  margin: 20px auto;
  align-items: center;

  label {
    min-width: 20%;
    padding: 10px 25px 10px 15px;
    border-top-right-radius: 25px;
    display: inline-block;
    background-color: #8630F2;
    color: #ffffff;
    font-family: Exo2;
    font-size: 13pt;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 10px 25px 10px 10px;
    border-color: #b8b8b8;
    border-style: solid;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;    
    font-family: Exo2;
    font-size: 15pt;
  }
`;

export const Subtitle = styled.label`
  display: block;
  margin: 30px auto;
  align-items: center;
  font-family: Exo2;
  font-weight: 500;
  color: #2A2944;
  text-align: center;

  strong {
    color: #8630F2;
  }
`;

export const ButtonWrapper = styled.button`
  width: 100%;
  padding: 0;
`;

export const Button = styled(Link)`
  border: 2px solid #fafafa;
  font-size: var(--paragraph-size);
  color: #fafafa;
  padding: 0.5rem 2rem;
  border-radius: 100px;
  transition: all 0.3s;
  font-family: Exo2;
  font-size: 13pt;
  font-weight: bold;
  &:hover {
    background: #fafafa;
    color: ${(props) => props.theme.main};
  }
`;