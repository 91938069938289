import React from "react";

import * as S from "./styled";
import CatBorderedTitle from "../CatBorderedTitle";

const CatContact = () => {
  return (
      <S.CatContact>
        <S.Wrapper>
          <CatBorderedTitle align="center" title={"Formulário de Contato"} color="#2A2944" />
          <S.CatContactForm name="contact" action="/index" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact"></input>
            <S.CatContactFormField>
              <label htmlFor="name">Nome Completo</label>
              <input id="name" type="text" name="name" placeholder="Clique aqui para digitar..." />
            </S.CatContactFormField>
            <S.CatContactFormField>
              <label htmlFor="email">Email Corporativo</label>
              <input id="email" type="text" name="email" placeholder="Clique aqui para digitar..." />
            </S.CatContactFormField>
            <S.CatContactFormField>
              <label htmlFor="company">Empresa</label>
              <input id="company" type="text" name="company" placeholder="Clique aqui para digitar..." />
            </S.CatContactFormField>
            <S.CatContactFormField>
              <label htmlFor="role">Cargo</label>
              <input id="role" type="text" name="role" placeholder="Clique aqui para digitar..." />
            </S.CatContactFormField>
            <S.CatContactFormField>
              <label htmlFor="telephone">Telefone</label>
              <input id="telephone" type="text" name="telephone" placeholder="Clique aqui para digitar..." />
            </S.CatContactFormField>
            <S.CatContactFormField>
              <label htmlFor="subject">Assunto</label>
              <input id="subject" type="text" name="subject" placeholder="Clique aqui para digitar..." />
            </S.CatContactFormField>
            <S.Subtitle>
              Ao informar meus dados eu concordo em receber comunicações e com a <strong>Política de privacidade.</strong>
            </S.Subtitle>
            <S.ButtonWrapper>
              <S.Button className="button is-primary is-rounded" type="submit">
                Enviar
              </S.Button>
            </S.ButtonWrapper>
          </S.CatContactForm>
        </S.Wrapper>
      </S.CatContact>      
  );
};

export default CatContact;