import React from "react";
import PropTypes from "prop-types";

import CatHeader from "../CatHeader";
import CatFooter from "../CatFooter";

import * as S from "./styled";
import GlobalStyles from "../../styles/global";

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />      
      <CatHeader />
      <S.LayoutMain>{children}</S.LayoutMain>
      <CatFooter />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;